a.a-light:link,
a.a-light:visited {
  color: rgb(170, 170, 170);
  padding: 4px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 25px;
  font-family: "Century Gothic";
  /*font-family: 'UniversNextPro-Light', Helvetica, Arial;*/
  /*font-family: 'Malgun Gothic';*/
}

a.a-light-s:link,
a.a-light-s:visited {
  color: rgb(170, 170, 170);
  padding: 4px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  font-family: "Century Gothic";
  /*font-family: 'UniversNextPro-Light', Helvetica, Arial;*/
  /*font-family: 'Malgun Gothic';*/
  margin-left: 15px;
}

a.a-dark-s:link,
a.a-dark-s:visited {
  color: rgb(59, 59, 59);
  padding: 4px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  font-family: "Century Gothic";
  /*font-family: 'UniversNextPro-Light', Helvetica, Arial;*/
  /*font-family: 'Malgun Gothic';*/
  margin-left: 15px;
}

a.a-dark:link,
a.a-dark:visited {
  color: rgb(59, 59, 59);
  padding: 4px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 25px;
  font-family: "Century Gothic";
  /*font-family: 'Malgun Gothic';*/
}

div.sidenav {
  height: 100%;
  width: 250px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  /*position: fixed;*/
}

div.div-start {
  background-color: rgba(255, 255, 255, 0.637);
  margin-left: 40px;
}
a.a-darkStart:link,
a.a-darkStart:visited {
  color: rgb(59, 59, 59);
  padding: 4px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 25px;
  font-family: "Century Gothic";
  /*font-family: 'Malgun Gothic';*/
  margin-top: 40px;
  margin-left: 0px;
}
.box-shadow-menu {
  position: relative;
  padding-left: 1.25em;
  padding-right: 1em;
}
.box-shadow-menu:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.25em;
  width: 1.4em;
  height: 0.1em;
  background: rgb(59, 59, 59);
  box-shadow: 0 0.4em 0 0 rgb(59, 59, 59), 0 0.8em 0 0 rgb(59, 59, 59);

}
