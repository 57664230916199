div.pagediv {
  height: 100%;
  /*width: 20px;*/
  margin-left: 0;
  margin-right: 0;
  float: "end";
}

a.a-link:link,
a.a-link:visited {
  color: rgb(170, 170, 170);
  text-align: left;
  text-decoration: none;
  display: inline-block;
  font-size: 25px;
  font-family: "Century Gothic";
  /*font-family: 'Malgun Gothic';*/
  /*margin-top: 30px;*/
}

.container3070 {
  display: grid;
  grid-template-columns: 30% 70%;
  /*grid-gap: 1rem;*/
  grid-auto-flow: row;
  align-content: space-between;
  /*margin-right: 15px;*/
}
.container6040 {
  display: grid;
  grid-template-columns: 60% 40%;
  /*grid-gap: 1rem;*/
  grid-auto-flow: row;
  align-content: space-between;
  /*margin-right: 15px;*/
}
.container5050 {
  display: grid;
  grid-template-columns: 50% 50%;
  /*grid-gap: 1rem;*/
  grid-auto-flow: row;
  align-content: space-between;
  /*margin-right: 15px;*/
}

.containerProject {
  display: grid;
  grid-template-columns: 20% 80%;
  /*grid-gap: 1rem;*/
  grid-auto-flow: row;
  align-content: space-between;
  /*margin-right: 15px;*/
}

.item {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
  margin-left: 20px;
}

a.a-title {
  color: rgb(59, 59, 59);
  text-align: left;
  text-decoration: none;
  display: inline-block;
  font-size: 25px;
  font-family: "Century Gothic";
  /*font-family: 'Malgun Gothic';*/
}
a.a-text {
  color: rgb(160, 160, 160);
  text-align: left;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  font-family: "Century Gothic";
  /*font-family: 'Malgun Gothic';*/
}
a.a-textBig {
  color: rgb(160, 160, 160);
  text-align: left;
  text-decoration: none;
  display: inline-block;
  font-size: 22px;
  font-family: "Century Gothic";
  /*font-family: 'Malgun Gothic';*/
}
a.a-textBigLink {
  color: rgb(160, 160, 160);
  text-align: left;
  text-decoration: none;
  display: inline;
  font-size: 22px;
  font-family: "Century Gothic";
  /*font-family: 'Malgun Gothic';*/
}
a.a-textBigEng {
  color: rgb(160, 160, 160);
  text-align: left;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  font-family: "Century Gothic";
  /*font-family: 'Malgun Gothic';*/
  /*font-style: italic;*/
}

div.div-leftborder {
  border-left: solid rgb(160, 160, 160);
}
div.div-eng {
  margin-left: 30px;
  margin-right: 30px;
}

.containerTimeline {
  display: grid;
  grid-template-columns: 90% 10%;
  /*grid-gap: 1rem;*/
  grid-auto-flow: row;
  align-content: space-between;
  /*margin-right: 15px;*/
}
div.itemTimeline {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
  margin-left: 20px;
}
a.a-titleTimeline {
  color: rgb(59, 59, 59);
  width: 100%;
  text-align: right;
  text-decoration: none;
  display: inline-block;
  font-size: 25px;
  font-family: "Century Gothic";
}
a.a-titleTimelineSmall {
  color: rgb(160, 160, 160);
  width: 100%;
  text-align: right;
  text-decoration: none;
  display: inline-block;
  font-size: 25px;
  font-family: "Century Gothic";
}
a.a-textTimeline {
  color: rgb(160, 160, 160);
  width: 100%;
  text-align: right;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  font-family: "Century Gothic";
}
div.div-timelineLine {
  position: right;
  width: 2px;
  padding-top: 20px;
  background: rgb(160, 160, 160);
}
div.div-timelineCircle {
  transform: translate(-6px, 9px);

  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: inherit;
}
div.div-timelineCircleSmall {
  transform: translate(-6px, 9px);

  width: 10px;
  height: 10px;
  border-radius: 50%;
  /*background: inherit;*/
  background: rgb(255, 255, 255);
  border: 2px solid rgb(160, 160, 160);
}

div.skillsCV {
  height: 100%;
  width: 250px;
  margin-left: 30%;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  /*position: fixed;*/
}
div.div-timelineLineBottom {
  position: top;
  width: 2px;
  padding-top: 20px;
  background: rgb(160, 160, 160);
}
